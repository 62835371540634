















































import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app.module";

@Component
export default class NavigationComponent extends Vue {
    private drawer: boolean = false;
    private mini: boolean = true;
    private group: number = 0;

    /*****        component watchers      *****/
    /*****      main component methods    *****/

    // switch applicationlocale
    private onSwicthLocale(value: string): string {
        const locale: string = value.toLowerCase();
        AppModule.SWITCH_LOCALE(locale);

        return locale;
    }

    private scrollToView(elementID: string): void {
        const route: string = this.$route.name || "";
        if (route != "home.index") {
            this.$router.replace({ name: "home.index" });
            return;
        }
        // eslint-disable-next-line
        document!.querySelector(elementID)!.scrollIntoView({ behavior: "smooth" });
        this.drawer = false;
    }

    /*****         helper methods         *****/
    /*****      vue lifecycle methods     *****/
}
