import Vue from "vue";
import Vuex from "vuex";

import { AppState } from "@/store/states/app.state";


Vue.use(Vuex);

export interface RootState {
    app: AppState;
}


const store = new Vuex.Store<RootState>({});

export default store;
