







import {Component, Vue} from "vue-property-decorator";


@Component
export default class App extends Vue {
    /*****        component watchers      *****/
    /*****      main component methods    *****/
    /*****         helper methods         *****/
    /*****      vue lifecycle methods     *****/
}
