import HomeLayout from "@/layouts/home.layout.vue";

import HomeRouter from "@/router/home/home.router";
import TermsRouter from "@/router/home/terms.router";

export default [
    {
        path: "/",
        component: HomeLayout,
        children: [
            ...HomeRouter,
            ...TermsRouter,
        ],
    },
];
