




































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class HeroComponent extends Vue {
    private windowWidth: number = window.innerWidth;
    private windowHeight: number = window.innerHeight;

    private playstore: string = "https://play.google.com/store/apps/details?id=app.drinkky";
    private appstore: string = "https://www.apple.com/app-store/";

    private get smallPhoneScreen(): boolean {
        return this.windowHeight < 780 && !this.smallerPhoneScreen;
    }

    private get smallerPhoneScreen(): boolean {
        return this.windowHeight < 600;
    }

    /*****        component watchers      *****/
    /*****      main component methods    *****/

    private onResize(): void {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }

    /*****         helper methods         *****/
    /*****      vue lifecycle methods     *****/

    mounted() {
        window.addEventListener("resize", this.onResize);
    }
}
