import HomePage from "@/pages/home.page.vue"

export default  [
    {
        path: "/",
        name: "home.index",
        component: HomePage,
        meta: {
            guard: ["guest"],
            title: "Home",
        },
    },
];
