import {Route, RouteRecord} from "vue-router";


const appGuards = {
    //  guest guard - allow only guests to access specific routes
    guest: async (route: Route): Promise<Route> => {
        return route;
    },

    // redirect if requested page not found
    notFound: async (route: Route): Promise<void> => {
        if (!route.matched.length) throw {name: "home.index"}
    },

    //  set document title.
    setTitle(route: Route): void {
        let title: string = "Drinkky :: ";

        route.matched.forEach((matchedRoute: RouteRecord) => {
            if (matchedRoute.meta.hasOwnProperty("title")) {
                title += matchedRoute.meta.title + " - ";
            }
        });

        document.title = title.substr(0, title.length - 3);
    },
};

export default {
    init: async (route: Route, next: Function) => {
        appGuards.setTitle(route);

        // apply guards - store route guards for later verification
        const guards: Array<string> = [];

        route.matched.forEach((matchedRoute: RouteRecord) => {
            if (matchedRoute.meta.hasOwnProperty("guard")) {
                matchedRoute.meta.guard.forEach((guard: string) => {
                    guards.push(guard);
                });
            }
        });

        try {
            // verify if page exists
            await appGuards.notFound(route);
            // verify requested route guards
            // eslint-disable-next-line
            for (const guard of guards) await (appGuards as any)[guard](route);

            next();
        } catch(e) {          
            next(e);
        }
    },
};
