import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import { AppModule } from "@/store/modules/app.module";

import HomeIndexRouter from "@/router/home/index.router";

import guards from "@/guards";


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    ...HomeIndexRouter,
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

// on application reload fetch and validate user data
router.beforeEach(async (to: Route, from: Route, next: Function) => {
    await AppModule.INIT_APP();

    // check router guards
    await guards.init(to, next);
});


export default router
