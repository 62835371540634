









import { Component, Vue } from "vue-property-decorator";
import TermsAndConditionComponent from "@/components/terms-and-conditions.component.vue";


@Component({
    components: {
        TermsAndConditionComponent,
    }
})
export default class TermsPage extends Vue {
    /*****        component watchers      *****/
    /*****      main component methods    *****/
    /*****         helper methods         *****/
    /*****      vue lifecycle methods     *****/
}
