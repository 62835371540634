import Vue from "vue";
import Vuetify from "vuetify";
import "@mdi/font/css/materialdesignicons.css";


Vue.use(Vuetify);

export default new Vuetify({
    treeShake: true,
    theme: {
            options: {
                customProperties: true,
            },
        themes: {
            light: {
                primary: "#070287",//"#3D3885",
                secondary: "#F7B500",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107"
            },
        },
    },
    icons: {
        iconfont: "mdi",
    },
});
