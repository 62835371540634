




















import { Component, Vue } from "vue-property-decorator";
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";

@Component({
    components: {
        VueDPlayer,
    },
})
export default class TutorialComponent extends Vue {
    private options: object = {
        logo: require("@/assets/images/logo.png"),
        video: {
            url: "https://storage.googleapis.com/drinkky-bucket/intro.mp4",
        },
        autoplay: false,
    };

    /*****        component watchers      *****/
    /*****      main component methods    *****/
    /*****         helper methods         *****/
    /*****      vue lifecycle methods     *****/
}
