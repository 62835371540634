



























































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class TermsAndConditionComponent extends Vue {
    /*****        component watchers      *****/
    /*****      main component methods    *****/
    /*****         helper methods         *****/
    /*****      vue lifecycle methods     *****/
}
