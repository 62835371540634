/* eslint-disable */
import _Vue from "vue";
import { AppModule } from "@/store/modules/app.module";
import { TranslationInterface } from "@/interfaces/translation.interface";


export default function TranslationPlugin(Vue: typeof _Vue, options?: any): void {
    Vue.prototype.trans = (key: string, ...args: Array<any>): string => {
        if (!AppModule.locale) return "";

        let locale: string = AppModule.locale;

        if (!!args.length && typeof args[0] === "string") locale = args[0];

        const trans: any = AppModule.translations.find((e: TranslationInterface) => e.locale === locale);
        const value: string = trans.translations[key];

        return value;
    };
}

declare module "vue/types/vue" {
    interface Vue {
        trans(key: string, ...args: Array<any>): string;
    }
}
