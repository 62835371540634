











import { Component, Vue } from "vue-property-decorator";
import NavigationComponent from "@/components/navigation/navigation.component.vue";
import HeroComponent from "@/components/hero.component.vue";
import FeaturesComponent from "@/components/features.component.vue";
import TutorialComponent from "@/components/tutorial.component.vue";
import ContactsComponent from "@/components/contacts.component.vue";

@Component({
    components: {
        NavigationComponent,
        HeroComponent,
        FeaturesComponent,
        TutorialComponent,
        ContactsComponent,
    },
})
export default class HomePage extends Vue {
    /*****        component watchers      *****/
    /*****      main component methods    *****/
    /*****         helper methods         *****/
    /*****      vue lifecycle methods     *****/
}
