import TermsPage from "@/pages/terms.page.vue"

export default  [
    {
        path: "terms",
        name: "terms.index",
        component: TermsPage,
        meta: {
            guard: ["guest"],
            title: "Terms and Conditions",
        },
    },
];
