























































































































































































































import {Component, Vue} from "vue-property-decorator";


@Component
export default class FeaturesComponent extends Vue {
    private windowWidth: number = window.innerWidth;
    private windowHeight: number = window.innerHeight;

    private get smallPhoneScreen(): boolean {
        return this.windowHeight < 740 && !this.smallerPhoneScreen;
    }

    private get smallerPhoneScreen(): boolean {
        return this.windowHeight < 670;
    }

    private get cards() {
        return [
            {
                id: "problem",
                title: this.trans("feature.first.title"),
                list: [
                    this.trans("feature.first.list.item1"),
                    this.trans("feature.first.list.item2"),
                    this.trans("feature.first.list.item3"),
                    this.trans("feature.first.list.item4"),
                ],
                etc: this.trans("feature.first.etc"),
            },
            {
                id: "solution",
                title: this.trans("feature.second.title"),
                etc1: this.trans("feature.second.etc1"),
                etc2: this.trans("feature.second.etc2"),
            },
            {
                id: "usage",
                title: this.trans("feature.third.title"),
                etc: this.trans("drinkky") + " " + this.trans("feature.third.etc"),
            }
        ]
    }
    /*****        component watchers      *****/
    /*****      main component methods    *****/

    private onResize(): void {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }


    /*****         helper methods         *****/
    /*****      vue lifecycle methods     *****/

    mounted() {
        window.addEventListener("resize", this.onResize);
    }
}
