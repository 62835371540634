import store from "@/store/store";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AppState } from "@/store/states/app.state";
import { TranslationInterface } from "@/interfaces/translation.interface";
import { localeChanged } from "vee-validate";

import json from "@/assets/json/translations.json";


@Module({
    dynamic: true,
    name: "App",
    namespaced: true,
    store
})
class App extends VuexModule {
    public base: AppState = {
        loading: true,
        locale: "en",
        theme: "light",
        translations: [],
    };

    public get loading(): boolean {
        return this.base.loading;
    }

    public get locale(): string {
        return this.base.locale;
    }

    public get theme(): string {
        return this.base.theme;
    }

    public get translations(): Array<TranslationInterface> {
        return this.base.translations;
    }

    public get translation(): TranslationInterface|undefined {
        const hasTranslation: boolean = !!this.translations && this.translations.hasOwnProperty("locale");
        return hasTranslation ? this.base.translations.find((e: TranslationInterface) => e.locale === this.locale) : undefined;
    }
    

    // initialize application states
    @Action
    public async INIT_APP(): Promise<void> {
        this.UPDATE_LOADING(true);

        await Promise.all([
            this.FETCH_LOCALE(),
            this.FETCH_TRANSLATION(),
            this.FETCH_THEME(),
        ]);

        this.UPDATE_LOADING(false);
    }


    // retrieve loading state
    @Action
    public async FETCH_LOADING(loading: boolean = false): Promise<boolean> {
        return loading;
    }

    // retrieve locale state
    @Action
    public async FETCH_LOCALE(locale: string = "en"): Promise<string> {
        const localeStorage: string = String(localStorage.getItem("locale"));
        const localeUser: string = locale;
        
        return localeStorage || localeUser;
    }

    // retrieve translation by requested locale - in fallback return to previous locale
    @Action({commit: "UPDATE_TRANSLATIONS"})
    public async FETCH_TRANSLATION(): Promise<Array<TranslationInterface>> {
        const trans: Array<TranslationInterface> = json;
        return trans;
    }

    // retrieve theme state
    @Action
    public async FETCH_THEME(theme: string = "light"): Promise<string> {
        return theme;
    }

    // switch locale state
    @Action({commit: "UPDATE_LOCALE"})
    public async SWITCH_LOCALE(locale: string = "en"): Promise<string> {
        this.UPDATE_LOADING(true);

        localStorage.setItem("locale", locale);
        localeChanged();

        this.UPDATE_LOADING(false);

        return locale;
    }
    
    // update loading state
    @Mutation
    public UPDATE_LOADING(loading: boolean): boolean {
        return this.base.loading = loading;
    }
    
    // update locale state
    @Mutation
    public UPDATE_LOCALE(locale: string): string {
        return this.base.locale = locale;
    }

    // update translation state
    @Mutation
    public UPDATE_TRANSLATIONS(translations: Array<TranslationInterface>): Array<TranslationInterface> {
        return this.base.translations = translations;
    }
    
    // update theme state
    @Mutation
    public UPDATE_THEME(theme: string): string {
        return this.base.theme = theme;
    }
}

export const AppModule = getModule(App);
