import Vue from "vue"
import App from "@/app.vue"
import router from "@/router/router"
import store from "@/store/store"
import "@/sass/variables.scss";
import vuetify from "@/plugins/vuetify";


import TranslationPlugin from "@/plugins/translations.plugin";

Vue.use(TranslationPlugin);

Vue.config.productionTip = false


const app = new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount("#app");

export default app;


