









import {Component, Vue} from "vue-property-decorator";
import NavigationComponent from "@/components/navigation/navigation.component.vue";


@Component({
    components: {
        NavigationComponent,
    }
})
export default class HomeLayout extends Vue {
    /*****        component watchers      *****/
    /*****      main component methods    *****/
    /*****         helper methods         *****/
    /*****      vue lifecycle methods     *****/
}
