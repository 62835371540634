













































































import {Component, Vue} from "vue-property-decorator";


@Component({
    components: {
        TermsAndConditionComponent: () => import("@/components/terms-and-conditions.component.vue"),
    }
})
export default class ContactsComponent extends Vue {
    /*****        component watchers      *****/
    /*****      main component methods    *****/
    /*****         helper methods         *****/
    /*****      vue lifecycle methods     *****/
}
